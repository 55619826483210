import './style.scss';

const ContentSection = (props: { content: JSX.Element }) => {
    return (
        <section className='content'>
            <div className='container'>
                <div className='text font'>
                    {props.content}
                </div>
            </div>
        </section>
    )
}

export default ContentSection;