import BtnStore from '../../components/btn-store';
import './style.scss';

const HomeSection = () => {
    return (
        <section className='home'>
            <div className='container'>
                <div className='title'>
                    Let Your Libido Drive You
                </div>
                <div className='subtitle'>
                    Simple practices and courses for a juicy love life
                </div>
                <img className='image' src={require("../../../assets/images/home.png")} alt='' />
                <div className='actions'>
                    <BtnStore url='https://apps.apple.com/us/app/biyaku-libido-practices/id6463021577' icon={require("../../../assets/icons/appstore.svg").default}>
                        App Store
                    </BtnStore>
                    <BtnStore url='https://play.google.com/store/apps/details?id=com.biyaku' icon={require("../../../assets/icons/playstore.svg").default}>
                        Google Play
                    </BtnStore>
                </div>
            </div>
        </section>
    )
}

export default HomeSection;