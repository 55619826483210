import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PublicLayout } from '../views/layout';
import HomePage from '../views/pages/home';
import TermsPage from '../views/pages/terms';
import PrivacyPage from '../views/pages/privacy';
import { isIOS, isMacOs } from 'react-device-detect';

function MainRouter() {
    if (window.location.pathname == "/update") {
        if (isIOS || isMacOs) {
            window.location.href = "https://apps.apple.com/tr/app/biyaku-meditation-intimacy/id6463021577";
        }
        else {
            window.location.href = "https://play.google.com/store/apps/details?id=com.biyaku";
        }
    }

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route element={<PublicLayout></PublicLayout>}>
                        <Route path={Urls.Home} element={<HomePage />}></Route>
                        <Route path={Urls.Terms} element={<TermsPage />}></Route>
                        <Route path={Urls.Privacy} element={<PrivacyPage />}></Route>
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    )
}

export enum Urls {
    Home = "/",
    Terms = "/terms-and-conditions",
    Privacy = "/privacy-policy"
}

export default MainRouter;