import { Link } from "react-router-dom";
import { Urls } from "../../navigation/main";

function Header() {
    return <header>
        <div className="container">
            <Link to={Urls.Home} title="Biyaku Home">
                <img src={require("../../assets/icons/logo.svg").default} alt="Biyaku Logo" />
            </Link>
        </div>
    </header>
}

export default Header;