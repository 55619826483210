import { Link } from "react-router-dom";
import { Urls } from "../../navigation/main";

function Footer() {
    return <footer>
        <div className="container">
            <div className="top">
                <img className="logo desktop-only" src={require("../../assets/icons/logo.svg").default} alt="Biyaku Logo" />
                <div className="menu">
                    <a className="menu-item" href="https://www.biyaku.me/" title="Blog" target="_blank">
                        Blog
                        <img className="arrow mobile-only" src={require("../../assets/icons/arrow.svg").default} aria-hidden="true" />
                    </a>
                    <Link className="menu-item" to={Urls.Terms} title="Terms and Conditions">
                        Terms and Conditions
                        <img className="arrow mobile-only" src={require("../../assets/icons/arrow.svg").default} aria-hidden="true" />
                    </Link>
                    <Link className="menu-item" to={Urls.Privacy} title="Privacy policy">
                        Privacy policy
                        <img className="arrow mobile-only" src={require("../../assets/icons/arrow.svg").default} aria-hidden="true" />
                    </Link>
                </div>
                <div className="social">
                    <div className="social-title">
                        Follow us on
                    </div>
                    <div className="social-items">
                        <a className="social-item" href="https://www.instagram.com/biyaku.app/" title="Biyaku Instagram" target="_blank">
                            <img src={require("../../assets/icons/instagram.svg").default} alt="Biyaku Instagram" />
                        </a>
                        <a className="social-item" href="https://www.youtube.com/@biyakuapp" title="Biyaku Youtube" target="_blank">
                            <img src={require("../../assets/icons/youtube.svg").default} alt="Biyaku Youtube" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="bottom">
                <div className="copyright">
                    Biyaku 2023 ©
                </div>
            </div>
        </div>
    </footer>
}

export default Footer;