import './style.scss';
import { Outlet } from 'react-router-dom';
import Header from './header';
import Footer from './footer';

export const PublicLayout = () => {
    return <>
        <Header />
        <Outlet />
        <Footer />
    </>
}