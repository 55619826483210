import './style.scss';

type Props = { 
    url: string,
    icon: string,
    children: JSX.Element | JSX.Element[] | string
}

const BtnStore = (props: Props) => {
    return (
        <a className='btn-store' href={props.url} target='_blank'>
            <img src={props.icon} aria-hidden="true" />
            <div className='content'>
                <div className='pre'>
                    Download from the
                </div>
                <div className='title'>
                    {props.children}
                </div>
            </div>
        </a>
    )
}

export default BtnStore;